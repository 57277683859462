import { ApolloClient } from '@apollo/client';
import {
  V2Gateway_GetAliasByFromDocument,
  V2Gateway_GetAliasByFromQuery,
} from 'Generated/graphql';

const getRouteAlias = async (
  client: ApolloClient<object>,
  path: string,
): Promise<string | undefined> => {
  const aliasTo = await client.query<V2Gateway_GetAliasByFromQuery>({
    query: V2Gateway_GetAliasByFromDocument,
    variables: {
      from: path.toLowerCase(),
    }
  }).then(({ data }) => {
    if (data?.getAliasByFrom) {
      const { to } = data.getAliasByFrom;
      return to;
    }
  });

  return aliasTo;
};

export default getRouteAlias;

