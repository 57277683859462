/** @jsxImportSource react */
'use client';
import { NextPage } from 'next';
import {
  AppServerSideApolloClient,
} from 'Common/functions/ApolloServerSideClient';
import getRouteAlias from 'Common/functions/getRouteAlias';
import { usePathname, useRouter } from 'next/navigation';
import { AppRoutePath } from 'Pages/routes';
import { useEffect } from 'react';

const CatchAll: NextPage = () => {
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    (async () => {
      if (pathname) {
        const alias = await getRouteAlias(
          AppServerSideApolloClient.getClient(),
          pathname,
        );
        if (alias) {
          router.replace(alias);
          return;
        }
      }

      router.replace(AppRoutePath.BROWSE_SERVICE_TYPES);
    })();
  }, []);

  return <></>;
};

export default CatchAll;

